<template>
  <div class="aboutBox">
    <headers :color1="color1" :color2="color2"></headers>
    <div class="cont">
      <div class="w1400">
        <div class="title">
          <div class>确保人工智能技术可持续发展</div>
        </div>
        <div class="en">
          <div class>Ensure the sustainable development of AI technology</div>
        </div>
        <div class="en">
          <div class="yewu">针对不同业务场景设计人工智能合规解决方案</div>
        </div>
      </div>
    </div>

    <div class="info">
      <div class="w1400">
        <div class="title">成为一家最值得信赖的人工智能合规服务提供商</div>
        <div class="en">Become one of the most trusted AI compliance service providers</div>
        <div class="contBox">
          <div class="contentimg1"></div>
          <div class="content cont01">
            <div class="name">关于炽卯</div>
            <div class="infos">
              <div>炽卯是全球领先的人工智能合规服务提供商，专注于人工智能合规领域，为人工智能技术及产业发展提供有竞争力的人工智能合规解决方案、产品和服务。</div>
              <div>炽卯科技协助头部企业构建完整的AI合规管理体系，及时准确的提供高质量的决策服务，为企业带来高附加值和可持续发展。同时，我们还积极探索AI的安全机制，提供AI伦理安全、价值观安全、内容安全的技术路径，助力客户打造自身的核心竞争力。</div>
            </div>
          </div>
        </div>

        <div class="contBox">
          <div class="contentimg2"></div>
          <div class="content cont02">
            <div class="name">我们的团队</div>
            <div class="infos">
              <div>我们是一支由人工智能领域技术大牛、知名律师，行业规范制定者组建的团队，拥有强大丰富的算法合规、数据合规、内容安全、人工智能治理领域专家资源。团队成员深谙行业规律，对AI合规问题有深刻的理解和把握，能够迅速提供最优解决方案。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <help></help>
    <footers></footers>
  </div>
</template>

<script>
import help from "@/components/help.vue";
import footers from "@/components/footer.vue";
import headers from "@/components/header.vue";
export default {
  components: {
    help,
    footers,
    headers,
  },
  data() {
    return {
      color1: "#bc526d",
      color2: "#74335a",
    };
  },
};
</script>

<style scoped lang="less">
.aboutBox {
  background-color: #fff;
  width: 100%;

  .cont {
    background: url("../../static/img/aboutBanner.jpg") no-repeat;
    background-size: cover;
    width: 100%;
    color: #fff;
    position: relative;
    padding-bottom: 230px;
    .title {
      padding-top: 200px;
      font-weight: 600;
      font-size: 56px;
      margin-bottom: 25px;
    }

    .en {
      color: #faa0c3;

      .yewu {
        margin-top: 60px;
      }
    }
  }

  .cont::after {
    content: "";
    position: absolute;
    top: 50%;
  }
  .info {
    padding-top: 90px;

    .title {
      text-align: center;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .en {
      font-size: 24px;
      text-align: center;
      margin-bottom: 100px;
      padding-left: 435px;
    }

    .about {
      font-size: 56px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 80px;
    }

    .contBox {
      width: 100%;
      height: 580px;
      position: relative;
      margin-bottom: 200px;
      .content {
        width: 930px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        padding: 60px 80px;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px #bfb1ae;
        .name {
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .infos {
          line-height: 40px;
          font-size: 20px;
        }
      }
      .cont01 {
        right: 0;
      }
      .cont02 {
        left: 0;
      }
      .contentimg1 {
        width: 1340px;
        height: 580px;
        background: url("../../static/img/about01.jpg") no-repeat;
      }
      .contentimg2 {
        width: 1340px;
        height: 580px;
        float: right;
        background: url("../../static/img/about02.jpg") no-repeat;
      }
    }
  }

  .w1600 {
    width: 1600px;
    margin: 0 auto;
  }
  .w1400 {
    width: 1400px;
    margin: 0 auto;
  }
}
</style>
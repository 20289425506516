<template>
  <div class="header" >
    <div
      class="top"
      :style="{
        backgroundImage:
          'linear-gradient(to right, ' + color1 + ', ' + color2 + ')',
      }"
    >
      <div class="w1600">
        <img src="../static/img/logo.png" alt class="logo" @click="scrollToElement('top')" />
        <div class="nav">
          <div class="navItem" @click="scrollToElement('product')">
            产品服务
          </div>
          <div class="navItem" @click="scrollToElement('programme')">
            解决方案
          </div>
          <div class="navItem" @click="scrollToElement('about')">关于我们</div>
        </div>
        <!-- <button class="info" @click="info">合作咨询</button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    color1: {
      type: String,
      default: "#bc526d",
    },
    color2: {
      type: String,
      default: "#94294e",
    },
  },
  components: {},
  mounted() {},
  methods: {
    gohome() {
      this.$router.push({
        path: "/home",
      });
    },
    about() {
      this.$router.push({
        path: "/about",
      });
    },
    info() {
      this.$router.push({
        path: "/info",
      });
    },
    scrollToElement(target) {
      this.$router.push({
        path: "/home",
      });
      setTimeout(() => {
        const targetElement = document.getElementById(target);
        if (targetElement) {
          // 平滑滚动到目标元素
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
  },
};
</script>
<style scoped lang='less'>
.header {
  position: fixed;
  z-index: 33;
  width: 100%;
  .top {
    height: 56px;
  }
  .w1600 {
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    // padding: 0 28px;
    width: 1298px;
    .logo {
      display: block;
      width:138px;
      // height: 17.5px;
      margin-right: 30px;
      cursor: pointer;
    }
    .nav {
      height: 56px;
      display: flex;
      align-items: center;
      color: #fff;
      .navItem {
        padding: 0 30px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .info {
      background-color: #faa0c3;
      width: 98px;
      height: 28px;
      border-radius: 30px;
      border: none;
      color: #813257;
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      top: 50%;
      right: 28px;
      transform: translateY(-50%);
      cursor: pointer;
      
    }
  }
}
</style>
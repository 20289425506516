<template>
  <div class>
    <div class="footer">
      <div class="w1400">
        <div class="top">
          <img src="../static/img/logored.png" alt class="logo" />
          <div class="nav">
            <div class="navIte">
              <p>产品服务</p>
              <ul>
                <li>
                  <span>生成式人工智能上线备案</span>
                </li>
                <li>
                  <span>大语言模型备案</span>
                </li>
                <li>
                  <span>大模型测评</span>
                </li>
                <li>
                  <span>大模型安全评估</span>
                </li>
                <li>
                  <span>大模型备案</span>
                </li>
                <li>
                  <span>深度合成备案</span>
                </li>
              </ul>
            </div>
            <div class="navIte">
              <p>解决方案</p>
              <ul>
                <li>
                  <span>大模型服务</span>
                </li>
                <li>
                  <span>AIGC应用商</span>
                </li>
                <li>
                  <span>算法推荐服务提供者</span>
                </li>
              </ul>
            </div>
            <div class="navIte">
              <p>关于我们</p>
              <ul>
                <li>
                  <span>关于我们</span>
                </li>
                <li>
                  <span>我们的团队</span>
                </li>
                <li>
                  <span>发展历程</span>
                </li>
              </ul>
            </div>
            <div class="navItem">
              <p>联系我们</p>
              <div class="phone">
                <img src="../static/img/mobile.png" alt />
                400-007-2378
              </div>
              <div class="email">联系邮箱：chimao@aicompliance.cn</div>
            </div>
          </div>
          <img src="../static/img/code.png" alt class="code" />
        </div>
        <div class="bootm">
          Copyright © 2024 北京炽卯科技有限公司
          <span
            ><a href="https://beian.miit.gov.cn/"
              >京ICP备2023006853号-3</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='less'>
.footer {
  background-color: #1a2029;
  width: 100%;
  padding-top: 80px;
  .w1400 {
    width: 1400px;
    margin: 0 auto;
    .top {
      display: flex;
      margin-bottom: 50px;
      .logo {
        display: block;
        width: 146px;
        height: 30px;
        margin-right: 150px;
      }
      .nav {
        display: flex;
        .navIte {
          width: 218px;
          p {
            font-weight: 600;
            color: #fff;
            margin-bottom: 10px;
          }
          ul {
            width: 100%;
            li {
              margin-top: 15px;
              span {
                font-size: 14px;
                color: #adafb3;
                cursor: pointer;
              }
            }
          }
        }
        .navItem {
          width: 242px;
          p {
            color: #fff;
            margin-bottom: 10px;
          }
          .phone {
            color: #ae2c5f;
            font-size: 24px;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            margin-top: 60px;
            img {
              margin-right: 5px;
            }
          }
          .email {
            color: #fff;
            font-size: 14px;
          }
        }
      }
      .code {
        display: block;
        width: 170px;
        height: 190px;
        margin-left: 40px;
      }
    }
    .bootm {
      border-top: 1px solid #4a505c;
      padding: 20px 0;
      color: #4a505c;
      font-size: 14px;
      text-align: center;
      span {
        color: #ae2c5f;
      }
    }
  }
}
</style>
import VueRouter from "vue-router"
import Vue from "vue";
Vue.use(VueRouter)
import home from '@/pages/index/index.vue'
import about from '@/pages/about/index.vue'
import info from '@/pages/information/index.vue'


// 避免重复点击
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}



const router = new VueRouter({
    routes: [{
            path: '/',
            component: home,
        },
        {
            path: '/about',
            component: about
        },
        {
            path: '/info',
            component: info
        },
        // 重定向
        {
            path: '/home',
            redirect: '/'
        }
    ],
    mode:'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { x: 0, y: 0 };
        }
      }
})
export default router;
import Vue from 'vue'
import App from './App.vue'


import router from '@/router'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    // 当前设备是移动设备
    window.location.href = '/h5'
  }

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router
}).$mount('#app')
<template>
  <div class="infoBox">
    <heads :color1="color1" :color2="color2"></heads>
    <div class="w1400">
      <div class="title">立即预约咨询</div>
      <div class="small">炽卯AI咨询顾问将会尽快与您联系</div>
      <div class="info">
        <div class="infoItem">
          <div class="name">
            <span>*</span>请输入您的姓名
          </div>
          <div class>
            <input placeholder="请输入" border="surround" shape="circle" v-model="name" />
          </div>
        </div>
        <div class="infoItem">
          <div class="name">
            <span>*</span>请输入您的电话
          </div>
          <div class>
            <input placeholder="请输入" border="surround" shape="circle" v-model="phone" />
          </div>
        </div>
        <div class="infoItem">
          <div class="name">
            <span>*</span>请输入您的邮箱
          </div>
          <div class>
            <input placeholder="请输入" border="surround" shape="circle" v-model="phone" />
          </div>
        </div>
        <div class="infoItem">
          <div class="name">
            <span>*</span>请输入您的公司名称
          </div>
          <div class>
            <input placeholder="请输入" border="surround" shape="circle" v-model="company" />
          </div>
        </div>
        <div class="infoItem">
          <div class="name">
            <span>*</span>请输入您的所在行业
          </div>
          <div class>
            <input placeholder="请输入" border="surround" shape="circle" v-model="industry" />
          </div>
        </div>
        <div class="infoItem">
          <div class="name">
            <span>*</span>请输入您的咨询业务
          </div>
          <div class>
            <input placeholder="请输入" border="surround" shape="circle" v-model="business" />
          </div>
        </div>
        <div class="infoItem">
          <div class="name">
            <span>*</span>请输入您的详细描述
          </div>
          <div class>
            <textarea v-model="
purpose" placeholder="请输入"></textarea>
          </div>
        </div>
        <div class="exchange">预约专家交流</div>
      </div>
    </div>
  </div>
</template>

<script>
import heads from "@/components/header.vue";
export default {
  components: { heads },
  data() {
    return {
      name: "",
      phone: "",
      company: "",
      scale: [
        {
          name: "1-300",
        },
        {
          name: "300-1000",
        },
        {
          name: "1000-1500",
        },
        {
          name: "1500以上",
        },
      ],
      post: "",
      industry: "",
      business: "",
      purpose: "",
      color1: "#a8163a",
      color2: "#9e143a",
    };
  },
};
</script>

<style scoped lang="less">
.infoBox {
  // background-image: linear-gradient(to bottom right, #a9163a, #200034);
  background: url("../../static/img/info.jpg") no-repeat bottom;
  width: 100%;
  padding-bottom: 200px;
  .w1400 {
    width: 1400px;
    margin: 0 auto;
  }
  .title {
    color: #fff;
    font-size: 36px;
    margin-bottom: 30px;
    padding-top: 150px;
    font-weight: 600;
  }

  .small {
    color: #fff;
    margin-bottom: 90px;
    font-size: 22px;
  }

  .info {
    background-color: #fff;
    border-radius: 40px;
    padding-bottom: 100px;
    .infoItem {
      padding: 0 50px;

      .name {
        padding-top: 40px;
        margin-bottom: 20px;

        span {
          color: red;
        }
      }
      input {
        display: block;
        width: 650px;
        height: 50px;
        padding-left: 10px;
        border: 1px solid #cfcfcf;
        border-radius: 50px;
      }
      input:focus {
        outline: none;
      }
      textarea {
        display: block;
        width: 650px;
        height: 120px;
        border-radius: 15px;
        border: 1px solid #cfcfcf;
        padding-left: 10px;
        padding-top: 10px;
        resize: none;
      }
      textarea:focus {
        outline: none;
      }
      /deep/ .u-radio__icon-wrap {
        margin-right: 6px;
      }
    }

    .exchange {
      margin: 45px 0 0 50px;
      background-image: linear-gradient(to bottom, #772d7f, #bc2656);
      color: #fff;
      width: 240px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
    }
  }
}
</style>
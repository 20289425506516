<template>

  <div class="help">
    <div class="head">助力企业自信的拥抱人工智能</div>
    <div class="en">
      Help enterprises embrace artificial intelligence with confidence
    </div>
    <div class="exchange" @click="infoto">预约专家交流</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    infoto() {
      this.$router.push({
        path: "/info",
      });
    },
  },
};
</script>

<style scoped lang="less">
.help {
  width: 100%;
  height: 390px;
  background-image: linear-gradient(to right, #2d033a, #780341);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .head {
    padding-top: 70px;
    font-size: 36px;
    margin-bottom: 40px;
  }
  .en {
    margin-bottom: 35px;
  }
  .exchange {
    width: 300px;
    height: 60px;
    border-radius: 50px;
    background-image: linear-gradient(to bottom, #762e7f, #bd2656);
    text-align: center;
    line-height: 60px;
  }
}
</style>
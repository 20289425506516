<template>
  <div class>
    <headers :color1="color1" :color2="color2"></headers>
    <div class="homecontent" id="top">
      <div
        :style="divStyle"
        style="display: flex; align-items: center"
        class="cont"
      >
        <div class="w1400">
          <div class="cont-box">
            <!-- <div class="en">ChiMao • AI</div> -->
            <div class="en">
              <img class="logo4" src="../../static/img/logo5.png" alt="" />
            </div>
            <div class="titleone">人工智能合规大模型</div>
            <div class="info">
              <div class>
                深耕人工智能安全、法规、伦理、治理领域，实现智能化对话问答，支持自动化材料生成
              </div>
            </div>
            <div>
              <div class="experience">
                <a style="color: #a74060" href="https://chimaoai.com"
                  >立即体验</a
                >
              </div>
              <div class="logo">
                <div class="logoItem">
                  <img src="../../static/img/logo1.png" />
                  <span>ChiMao • AI</span>
                </div>
                <div class="logoItem">
                  <img src="../../static/img/logo2.png" />
                  <span>壹法一尊</span>
                </div>
                <!-- <div class="logoItem">
              <img src="../../static/img/logo3.png" />
              <span>智法时代</span>
            </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="service" id="product">
        <div class="w">
          <div class="w1400">
            <div
              :style="divStyle"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div style="width: 100%">
                <div class="title">产品服务</div>
                <div class="content">
                  针对不同业务场景，设计个性化AI合规解决方案。
                </div>
                <div class="serviceBox">
                  <div class="qualified">
                    <div class="front">
                      <div class="name">
                        <!-- <img src="../../static/img/icon1.png" /> -->
                        <span>人工智能合规</span>
                      </div>
                      <!-- <div class="arrow">→</div> -->
                      <div class="">
                        <div class="qualifiedText">
                          <img
                            src="../../static/img/icon_1.png"
                            alt=""
                            srcset=""
                          />
                          政策解读
                        </div>
                        <div class="qualifiedText">
                          <img
                            src="../../static/img/icon_2.png"
                            alt=""
                            srcset=""
                          />
                          决策建议
                        </div>
                        <div class="qualifiedText">
                          <img
                            src="../../static/img/icon_3.png"
                            alt=""
                            srcset=""
                          />
                          合规准入
                        </div>
                        <div class="qualifiedText">
                          <img
                            src="../../static/img/icon_4.png"
                            alt=""
                            srcset=""
                          />
                          危机管理
                        </div>
                        <div class="qualifiedText">
                          <img
                            src="../../static/img/icon_5.png"
                            alt=""
                            srcset=""
                          />
                          风险管控
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="serviceBoxL">
                    <div class="policy">
                      <div class="front">
                        <div class="name">
                          <img src="../../static/img/icon3.png" class="icon3" />
                          <span>合规准入</span>
                        </div>
                        <div class="arrow">
                          <img
                            src="../../static/img/jiantou.png"
                            class="icon2"
                          />
                        </div>
                      </div>
                      <div class="qualifiedf">
                        <div class="qualifiedItem">· 大模型备案</div>
                        <div class="qualifiedItem">· 深合备案</div>
                        <div class="qualifiedItem">· 算法备案</div>
                        <div class="qualifiedItem">· 个人信息出境信息备案</div>
                      </div>
                    </div>
                    <div class="policy">
                      <div class="front">
                        <div class="name">
                          <img src="../../static/img/icon5.png" class="icon5" />
                          <span>监管应对</span>
                        </div>
                        <div class="arrow">
                          <img
                            src="../../static/img/jiantou.png"
                            class="icon2"
                          />
                        </div>
                      </div>
                      <div class="qualifiedf" style="padding-left: 138px">
                        <div class="qualifiedItem">· 大模型检查</div>
                        <div class="qualifiedItem">· 算法检查</div>
                        <div class="qualifiedItem">· 现场核验</div>
                      </div>
                    </div>
                    <div class="policy" style="margin-bottom: 0">
                      <div class="front">
                        <div class="name">
                          <img src="../../static/img/icon2.png" class="icon2" />
                          <span>测试评估</span>
                        </div>
                        <div class="arrow">
                          <img
                            src="../../static/img/jiantou.png"
                            class="icon2"
                          />
                        </div>
                      </div>
                      <div class="qualifiedf pl">
                        <div class="qualifiedItem">· 大模型测评</div>
                        <div class="qualifiedItem">· 内容审核</div>
                        <div class="qualifiedItem">· 安全评估</div>
                        <div class="qualifiedItem">· 个人信息保护合规审计</div>
                      </div>
                    </div>

                    <div class="policy" style="margin-bottom: 0">
                      <div class="front">
                        <div class="name">
                          <img src="../../static/img/icon4.png" class="icon4" />
                          <span>政策解读</span>
                        </div>
                        <div class="arrow">
                          <img
                            src="../../static/img/jiantou.png"
                            class="icon2"
                          />
                        </div>
                      </div>
                      <div class="qualifiedf" style="padding-left: 138px">
                        <div class="qualifiedItem">· 闭门会议</div>
                        <div class="qualifiedItem">· 现场咨询</div>
                        <div class="qualifiedItem">· 企业内训</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :style="divStyle"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div style="width: 100%">
                <div class="title" style="padding-top: 10px" id="programme">
                  解决方案
                </div>
                <div class="content">
                  <div class="line">
                    随着《生成式人工智能服务管理暂行办法》的正式施行，强监管环境下，
                  </div>
                  <div class="line">
                    AI合规成为企业不可避免的挑战，也是企业存活的关键。
                  </div>
                </div>
                <div class="solve">
                  <div class="solveTitle">
                    <div
                      class="item"
                      :class="current == index ? 'active' : ''"
                      v-for="(item, index) in fanganList"
                      :key="index"
                      @click="qielei(index)"
                    >
                      <img :src="item.img" mode />
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                  <div class="title1" v-show="current == 0">
                    <div class="application">
                      <div class="name">
                        合规上线是
                        <span>“大模型俱乐部”</span> 的基本入场券
                      </div>
                      <div class="applicationinfo">
                        <div class="head">合规上线</div>
                        <img src="../../static/img/arrbottom.png" mode />
                        <div class="wenzi">
                          <div style="position: relative; left: -24px">
                            大模型备案
                          </div>
                          <img
                            src="../../static/img/jia.png"
                            style="margin-left: 34px"
                            mode
                          />
                          安全评估
                        </div>
                      </div>
                    </div>
                    <div class="application">
                      <div class="name">
                        持续运行是
                        <span>“大模型玩家们”</span> 的基础生命线
                      </div>
                      <div class="applicationinfo">
                        <div class="head">持续运行</div>
                        <img src="../../static/img/arrbottom.png" mode />
                        <div class="wenzi">
                          风险管控
                          <img src="../../static/img/jia.png" mode />
                          监管应对
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="title1" v-show="current == 1">
                    <div class="application">
                      <div class="name">
                        深合备案是
                        <span>AIGC应用</span>
                        上线的资质要求
                      </div>
                      <div class="applicationinfo">
                        <div class="head">合规上线</div>
                        <img src="../../static/img/arrbottom.png" mode />
                        <div class="wenzi">
                          <div style="position: relative; left: -55px">
                            深度合成备案
                          </div>
                          <img
                            src="../../static/img/jia.png"
                            style="margin-left: 10px"
                            mode
                          />
                          安全评估
                        </div>
                      </div>
                    </div>
                    <div class="application">
                      <div class="name">
                        <span>AIGC产品</span>
                        的命运跟“赛道”选择和合规布局息息相关 
                      </div>
                      <div class="applicationinfo">
                        <div class="head">可持续发展</div>
                        <img src="../../static/img/arrbottom.png" mode />
                        <div class="wenzi">
                          战略决策
                          <img src="../../static/img/jia.png" mode />
                          危机管理
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="title1" v-show="current == 2">
                    <div class="application">
                      <div class="name">
                        个性化推荐、排序精选、检索过滤、调度决策
                      </div>
                      <div class="applicationinfo">
                        <div class="wenzi">合规上线=算法备案+安全评估</div>
                      </div>
                    </div>
                    <div class="application">
                      <div class="name">“清朗”系列专项行动</div>
                      <div class="applicationinfo">
                        <div class="head">可持续发展</div>
                        <img src="../../static/img/arrbottom.png" mode />
                        <div class="wenzi">
                          政策解读
                          <img src="../../static/img/jia.png" mode />
                          监管应对
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- :style="divStyle"  style="display: flex;align-items: center;justify-content: center;" -->
      <div class="about" id="about">
        <div class="w1400">
          <div class="title">关于我们</div>
          <div class="content">
            <div class="line">炽卯是一家人工智能安全与伦理研究的服务公司</div>
            <div class="line">我们的使命是确保人工智能技术可持续发展</div>
          </div>
          <div class="aboutBox">
            <div class="aboutItem">
              <div class="head">使命</div>
              <div class="up">
                <div class="headCont">确保人工智能技术<br />可持续发展</div>
                <!-- <div class="headCont">可持续发展</div> -->
                <!-- <div class="headCont">释放人工智能社会变革潜力</div> -->
              </div>
            </div>
            <div class="aboutItem">
              <div class="head">愿景</div>
              <div class="up">
                <div class="headCont">
                  成为一家最值得信赖的<br />人工智能合规服务提供商                </div>
                <!-- <div class="headCont">人工智能合规服务提供商</div> -->
                <!-- <div class="headCont opct">1</div> -->
              </div>
            </div>
            <div class="aboutItem">
              <div class="head">价值观</div>
              <div class="up">
                <div class="headCont">
                  以客户为中心、尊重、<br />诚信、合作、创新
                </div>
                <!-- <div class="headCont">价值、合作、尊重、创新</div> -->
                <!-- <div class="headCont opct">1</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="partner">
        <div class="w1400">
          <div class="partnerHead">合作伙伴</div>
          <div class="imgBox">
            <div class="imgItem">
              <img src="../../static/img/partner01.jpg" />
            </div>
            <div class="imgItem">
              <img src="../../static/img/partner02.jpg" />
            </div>
            <div class="imgItem">
              <img src="../../static/img/partner03.jpg" />
            </div>
            <div class="imgItem">
              <img src="../../static/img/partner04.jpg" />
            </div>
            <div class="imgItem">
              <img src="../../static/img/partner05.jpg" />
            </div>
            <div class="imgItem">
              <img src="../../static/img/partner06.jpg" />
            </div>
            <div class="imgItem">
              <img src="../../static/img/partner07.jpg" />
            </div>
            <div class="imgItem">
              <img src="../../static/img/partner08.jpg" />
            </div>
            <!-- <div class="imgItem">
              <img src="../../static/img/partner09.jpg" />
            </div> -->
          </div>
          <!-- <img src="../../static/img/logo01.png" alt class="logoImg" /> -->
        </div>
      </div>
      <help></help>
      <footers></footers>
    </div>
  </div>
</template>
<script>
import help from "@/components/help.vue";
import footers from "@/components/footer.vue";
import headers from "@/components/header.vue";
export default {
  components: {
    help,
    footers,
    headers,
  },
  data() {
    return {
      color1: "#9f3654",
      color2: "#88284e",
      windowHeight: `${this.windowHeight}px`,
      fanganList: [
        {
          name: "大模型服务商",
          img: require("../../static/img/icon6.png"),
        },
        {
          name: "AIGC应用商",
          img: require("../../static/img/icon7.png"),
        },
        {
          name: "算法推荐服务提供",
          img: require("../../static/img/icon8.png"),
        },
      ],
      current: 0,
    };
  },
  computed: {
    divStyle() {
      return {
        height: `${this.windowHeight}px`,
        // 其他样式
      };
    },
  },
  methods: {
    qielei(index) {
      this.current = index;
    },
    updateWindowHeight() {
      this.windowHeight = window.innerHeight;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowHeight);
  },
  mounted() {
    this.updateWindowHeight();
    window.addEventListener("resize", this.updateWindowHeight);
  },
};
</script>
<style lang="less" scoped>
.homecontent {
  background-color: #fff;
  width: 100%;

  .cont {
    background: url("../../static/img/banner.jpg") no-repeat;
    background-size: 100% 100%;
    width: 100%;

    .en {
      color: #fff;
      // font-size: 30px;
      // margin-bottom: 18px;
    }

    .titleone {
      font-weight: 600;
      font-size: 56px;
      color: #fff;
      margin-bottom: 12px;
    }

    .info {
      color: #faa0c3;
      font-size: 16px;
      margin-bottom: 48px;
    }

    .experience {
      background-color: #faa0c3;
      width: 150px;
      height: 38px;
      border-radius: 50px;
      text-align: center;
      line-height: 38px;
      color: #a74060;
      margin-bottom: 64px;
      font-weight: 600;
      .a {
        color: #a74060 !important;
      }
    }

    .logo {
      display: flex;
      align-items: center;
      padding-bottom: 518px;
      // padding-left: 66px;
      .logoItem {
        padding: 0 35px;
        display: flex;
        align-items: center;

        img {
          width: 28px;
          height: 28px;
          display: inline-block;
        }

        span {
          color: #faa0c3;
          margin-left: 10px;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .logoItem:nth-child(2) {
        border-left: solid 1px #faa0c3;
        // border-right: solid 1px #faa0c3;
      }
      .logoItem:first-child {
        padding-left: 0px;
      }
    }
  }

  .service {
    width: 100%;
    height: 100%;
    // background: linear-gradient(to bottom right, #c7224b, #3b0435);
    background: url("../../static/img/service.jpg") no-repeat;
    background-size: 100% 100%;
    .w {
      width: 100%;
      height: 100%;
      // background: url("../../static/img/wang.png") no-repeat;
      .serviceBox {
        // padding: 0 30px;
        display: flex;
        justify-content: center;
        margin-bottom: 88px;

        .qualified {
          // background-color: #9d2758;
          background-image: url("../../static/img/cpfw.png");
          // border: 2px solid #ac193f;
          background-size: 100% 100%;
          border-radius: 30px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 30%;
          margin-right: 15px;
          cursor: pointer;
          transition: all 0.4s;
          .front {
            width: 188px;
            margin: 0 auto;
            .name {
              margin-bottom: 40px;

              img {
                width: 30px;
                height: 30px;
                display: inline-block;
                vertical-align: bottom;
              }

              span {
                font-weight: 600;
                font-size: 28px;
                margin-left: 10px;
              }
            }
            .arrow {
              color: #fff;
              font-size: 48px;
              font-weight: 600;
            }
          }

          .qualifiedf {
            width: 69%;
            margin: 0 auto;
            display: none;
            .qualifiedItem {
              line-height: 40px;
              font-size: 22px;
            }
          }
        }
        // .qualified:hover {
        //   background-color: #fff;
        // }
        // .qualified:hover .front {
        //   display: none;
        // }
        // .qualified:hover .qualifiedf {
        //   display: block !important;
        // }
        // .qualified:hover .qualifiedItem {
        //   color: #000;
        // }

        .serviceBoxL {
          width: 70%;
          height: 540px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .policy {
            width: 49%;
            // background-color: #9d2758;
            background-image: url("../../static/img/jx518.png");
            // border: 2px solid #ac193f;
            background-size: 100% 100%;
            border-radius: 30px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            transition: all 0.4s;
            cursor: pointer;
            margin-bottom: 18px;
            .front {
              width: 156px;
              margin: 0 auto;
              height: 128px;
              line-height: 128px;
              .name {
                // margin-bottom: 28px;
                color: #fff;
                display: flex;
                align-items: center;

                img {
                  width: 30px;
                  display: inline-block;
                }

                span {
                  font-weight: 600;
                  font-size: 28px;
                  margin-left: 10px;
                }
              }

              .arrow {
                color: #fff;
                // font-size: 48px;
                // font-weight: 600;
                text-align: center;
                line-height: 0;
                img {
                  width: 36px;
                }
              }
            }
            .qualifiedf {
              display: none;
              // text-align: center;
              padding-left: 98px;
              .qualifiedItem {
                line-height: 32px;
                font-size: 20px;
                color: #fff;
              }
            }
            .pl {
              // padding-left: 40px;
              text-align: left;
            }
          }
          .policy:hover {
            background: white;
          }
          .policy:hover .front {
            display: none;
          }
          .policy:hover .qualifiedf {
            display: block !important;
          }
          .policy:hover .qualifiedItem {
            color: #000;
          }

          .mb {
            margin-bottom: 15px;
          }

          .assessBox {
            position: relative;
            background-image: linear-gradient(to bottom, #c9254d, #662f89);
            padding: 80px 40px;
            border-radius: 20px;
            width: 400px;

            .qualifiedItem {
              line-height: 80px;
              font-size: 32px;
              color: #fff;
            }

            .close {
              position: absolute;
              top: 20px;
              right: 20px;

              img {
                display: block;
                width: 30px;
                height: 30px;
              }
            }
          }
          .center {
            text-align: center;
          }
        }
      }
      .solve {
        // padding: 0 30px;
        .solveTitle {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 48px;

          .active {
            background: linear-gradient(to right, #c9254d, #662f89);
            border-radius: 50px;
          }

          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 240px;
            padding: 0 30px;
            height: 60px;
            cursor: pointer;
            img {
              display: inline-block;
              width: 30px;
              height: 30px;
            }

            span {
              color: #fff;
              margin-left: 8px;
              font-size: 24px;
            }
          }
          .item:nth-child(2) {
            margin: 0 40px;
          }
        }
        .title1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .application {
            width: 49%;
            position: relative;
            overflow: hidden;
            padding-bottom: 100px;

            .done {
              width: 100%;
              height: 130px;
              border-top-left-radius: 30px;
              border-top-right-radius: 30px;
              background: linear-gradient(to right, #c9254d, #662f89);
              line-height: 130px;

              span {
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: #6b1738;
                border-radius: 50%;
                margin-left: 30px;
              }
            }

            .name {
              width: 100%;
              height: 88px;
              border-top-left-radius: 30px;
              border-top-right-radius: 30px;
              text-align: center;
              background: linear-gradient(to right, #c9254d, #662f89);
              line-height: 88px;
              color: #fff;
              font-size: 22px;

              span {
                font-weight: 600;
                font-size: 22px;
                margin: 0 10px;
              }
            }

            .applicationinfo {
              background-color: rgba(255, 255, 255, 0.1);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-bottom-left-radius: 30px;
              border-bottom-right-radius: 30px;
              color: #fff;
              height: 340px;
              .head {
                font-size: 28px;
                font-weight: 600;
                margin-bottom: 20px;
              }

              img {
                display: inline-block;
                height: 68px;
                width: 138px;
                margin-bottom: 24px;
              }

              .wenzi {
                font-size: 24px;
                display: flex;
                align-items: center;

                img {
                  display: inline-block;
                  height: 35px;
                  width: 35px;
                  margin: 0 60px;
                }
              }
            }
          }

          .application::before {
            // content: '';
            // border: 1px solid;
            // border-image: linear-gradient(to right, #8f41e9, #578aef) 1;
            // position: absolute;
            // top: 0;
            // left: 0;
            // width: 100%;
            // height: 660px;
            // border-top: none;
          }
        }
      }
    }
  }

  .about {
    background: url("../../static/img/about.png") no-repeat;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;

    .aboutBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      .aboutItem {
        color: #fff;
        width: 33.3%;
        height: 498px;
        position: relative;
        border-left: 1px solid;
        border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff) 1;
        padding-left: 40px;
        box-sizing: border-box;
        // padding-top: 150px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .head {
          font-size: 38px;
          margin-bottom: 40px;
          padding-left: 20px;
        }

        .up {
          display: none;
          .headCont {
            margin-bottom: 40px;
            padding-left: 20px;
            font-size: 18px;
            line-height: 46px;
          }
          .opct {
            opacity: 0;
          }
        }
      }
      .aboutItem:hover .up {
        display: block;
      }

      .aboutItem:hover::after {
        content: "";
        position: absolute;
        top: 148px;
        left: -2px;
        bottom: 0;
        width: 4px;
        height: 160px;
        border-radius: 10px;
        background-image: linear-gradient(to bottom, #e64f9e, #924ce9);
        animation: gradientSlide 1s ease-in-out forwards;
      }

      @keyframes gradientSlide {
        0% {
          opacity: 0;
          transform: translateY(60%);
        }
        50% {
          opacity: 1;
          transform: translateY(0);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  .partner {
    width: 100%;
    background-color: #630139;
    padding-bottom: 50px;
    .partnerHead {
      color: #fff;
      text-align: center;
      font-size: 36px;
      padding-top: 9%;
      position: relative;
      padding-bottom: 30px;
      margin-bottom: 44px;
    }
    .logoImg {
      display: block;
      width: 100%;
    }

    .imgBox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      .imgItem {
        width: 25%;
        padding: 30px 0;
        display: flex;
        justify-content: center;
        border: 1px solid;
        box-sizing: border-box;
        border-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.1)
          )
          1;
        img {
          display: block;
          width: 195px;
          height: 80px;
          border-radius: 30px;
          border-radius: 30px;
        }
      }
    }
  }

  .title {
    color: #fff;
    text-align: center;
    font-size: 36px;
    padding-top: 90px;
    position: relative;
    padding-bottom: 25px;
    // margin-bottom: 40px;
  }

  // .title::after {
  //   content: "";
  //   width: 120px;
  //   height: 6px;
  //   background: linear-gradient(to right, #e64f9e, #924ce9);
  //   position: absolute;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   border-radius: 20px;
  // }

  .content {
    color: #fff;
    text-align: center;
    margin-bottom: 48px;
    padding: 0 30px;
    font-size: 16px;
    .line {
      line-height: 28px;
    }
  }
  .w1400 {
    width: 1298px;
    margin: 0 auto;
  }
}
.cont-box {
  position: relative;
  top: 200px;
}
.logo4 {
  width: 198px;
  position: relative;
  right: 12px;
  top: 20px;
}
.qualifiedText {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.qualifiedText img {
  width: 16px;
  margin-right: 8px;
}
a:hover{
  text-decoration: none;
}
</style>